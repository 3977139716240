// @flow
import * as React from 'react';
import withStyles, {
  type StyleRulesCallback,
  type WithStyles,
} from '@plugins/material-ui/hocs/withStyles';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import classnames from 'classnames';
import Lottie from 'react-lottie';

import * as animationData from './infography.json';

export type ClassKey = 'root';

export type Props = {
  ...$Exact<WithStyles<ClassKey>>,
  className?: string,
};

export type Styles = StyleRulesCallback<Theme, Props, ClassKey>;

export const styles: Styles = unusedTheme => ({
  root: {},
});

const AuroraDiagramAnimation = ({
  className,
  classes,
  ...props
}: Props): React.Node => {
  const lottieRef = React.useRef(null);

  return (
    <Lottie
      ref={lottieRef}
      options={{
        renderer: 'svg',
        animationData: animationData.default,
        loop: true,
        autoplay: false,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      }}
      className={classnames(classes.root, className)}
      {...props}
    />
  );
};

AuroraDiagramAnimation.defaultProps = {
  className: undefined,
};

export default withStyles<*, *, Props>(styles, { name: 'AuroraDiagramAnimation' })(AuroraDiagramAnimation);
